const parseMeasure = 'digitaldata-jsonparse';
const parseStartMarker = `${parseMeasure}-start`;
const parseEndMarker = `${parseMeasure}-end`;

// Getter will replace itself after being called once
Object.defineProperty(window, 'digitalData', {
  get: function () {
    performance.mark(parseStartMarker);
    let data;
    try {
      data = JSON.parse(document.getElementById('digitalData').text);
    } catch (error) {
      reportError(error);
      data = {};
    }
    performance.mark(parseEndMarker);
    performance.measure(parseMeasure, parseStartMarker, parseEndMarker);
    data.event = data.event || [];
    deepFreeze(data, ['event']);

    // Self replace with frozen data from parsed JSON
    Object.defineProperty(window, 'digitalData', { value: data });

    return data;
  },
  configurable: true,
});

// Workaround for https://github.com/adobe/reactor-turbine/issues/116
// Must be executed before Adobe Launch script, which look like: window._satellite=window._satellite||{},window._satellite.container={extensions:{...
window._satellite = {
  set container(container) {
    // register our extension to be the first one (object keys are kept in declaration order)
    // Note: iteration respect insertion order since ES2015
    container.extensions = Object.assign({ 'fnac-website': {} }, container.extensions);

    // Override this inital setter with definitive value
    Object.defineProperty(this, 'container', {
      value: container,
      configurable: true,
      enumerable: true,
      writable: true,
    });
  },
};

/**
 * Freeze object from JSON is not an issue but could if contains window of reference itself
 *
 * @param {object} object
 * @param {string[] | null} ignored
 * @returns {object}
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze#What_is_shallow_freeze
 */
function deepFreeze(object, ignored) {
  const propNames = Reflect.ownKeys(object).filter((key) => (ignored?.indexOf(key) ?? -1) < 0);

  // Freeze properties before freezing self
  propNames.forEach((key) => {
    const value = object[key];
    if ((value !== null && typeof value === 'object') || typeof value === 'function') {
      deepFreeze(value, ignored);
    }
  });

  return Object.freeze(object);
}
